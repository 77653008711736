export const environment = {
    pageName: 'AFW CMS',
    appFlavor: 'afw',
    production: false,
    appEnv: 'qa',
    googleMapsKey: 'AIzaSyDtNNFEoPKiPmr3QXOcDuJStw9X7bxAp8c',
    apiConfig: {
        projectEndpoint: 'https://afw-api-qa.itxi.aero',
        apiKey: '20123a58-221f-4398-90a9-ecfee9fc6f1d',
    },
    firebase: {
        apiKey: 'AIzaSyDtNNFEoPKiPmr3QXOcDuJStw9X7bxAp8c',
        authDomain: 'africa-world-airlines.firebaseapp.com',
        databaseURL:
            'https://africa-world-airlines-qa.europe-west1.firebasedatabase.app',
        projectId: 'africa-world-airlines',
        storageBucket: 'africa-world-airlines-qa',
        messagingSenderId: '512192666467',
        appId: '1:512192666467:web:c127aea2545b6840011156',
        measurementId: 'G-T7B97798E4',
    },
    googleAnalyticsTrackId: '',
    rolesWithLoginAccess: [
        {
            role: 'admin',
            route: 'dashboard/content/users',
            node: 'users',
        },
        {
            role: 'power_user',
            route: 'dashboard/content/airports',
            node: 'airports',
        },
        {
            role: 'viewer',
            route: 'dashboard/content/airports',
            node: 'airports',
        },
    ],
    sessionTokenCheckInterval: 300000,
    getAllFlightsListInterval: 300000,
    sessionTokenExtension: true,
    defaultRoute: 'dashboard/content/users',
    enableRandomBackground: false,
    isGoogleAnalyticEnabled: false,
    airportCoordinates: {
        latitude: 5.605186,
        longitude: -0.166786,
        ISO3166_1_Alpha_2: 'US',
    },
    videoCategories: [],
    userTableLanguages: [{ key: 'en', label: 'LANGUAGES.ENGLISH_ONLY' }],
    userTablesRole: [
        { key: 'admin', label: 'ROLES.ADMIN' },
        { key: 'power_user', label: 'ROLES.POWER_USER' },
        {
            key: 'viewer',
            label: 'ROLES.VIEWER',
        },
    ],
    formLanguages: [
        { key: 'en', title: 'LANGUAGES.ENGLISH', label: 'FORMS.EN_LANG' },
        { key: 'es', title: 'LANGUAGES.ESPANOL', label: 'FORMS.ES_LANG' },
    ],
    microsoftSSOClientId: '',
    googleSSOClientId:
        '512192666467-fjr80iqd1un6ncmht5304d99cjm74k2q.apps.googleusercontent.com',
    signInOptions: ['password', 'google'],
    appCategories: [],
    payment_method: [],
    payment_status: [],
    orientationArray: [],
    containerType: [],
    contentType: [],
    contentItems: [],
    equipmentType: [],
    departureTime: [],
    sectors: [],
    cartItems: [],
};
